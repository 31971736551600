import styled from "styled-components"

export const StyledBlock = styled.div`
  margin: 2rem 0;
`

export default styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`
