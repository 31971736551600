import React from "react"
import Button from "../button"
import Container from "../container"
import Section from "../section"
import Flex from "../flex"
import Typography from "../typography"
import HomeJumbroTronImage from "../../assets/images/equipe_gastrocirurgica_cirurgia.png"
import StyledImage, { StyledTextWrapper } from "./styled"

const homeTeam = () => (
  <Section>
    <Container>
      <Flex flexWrap="wrap" align="center">
        <Flex child flexGtSm={50} flex={100}>
          <StyledImage
            src={HomeJumbroTronImage}
            alt="Equipe GastroCirúrgica com traje de cirúrgia"
          />
        </Flex>
        <Flex child flexGtSm={50} flex={100}>
          <StyledTextWrapper style={{ textAlign: "justify" }}>
            <Typography variant="h5" component="h2" color="primary">
              Especialistas no Aparelho Digestivo
            </Typography>
            <Typography variant="h1" color="secondary">
              Conheça a equipe de médicos cirurgiões
            </Typography>
            <Typography variant="bodyLarge">
              Experiente e capacitada para diagnosticar, realizar tratamento
              clínico e cirúrgico de diversas enfermidades relacionadas ao
              aparelho digestivo.
            </Typography>
            <Typography variant="bodyLarge">
              Dominam as últimas tecnologias e estão em constante evolução,
              trazendo o que há de mais moderno em soluções de saúde através de
              um atendimento de excelência.
            </Typography>
            <Button
              color="primary"
              size="large"
              shape="round"
              htmlType="link"
              to="/equipe"
            >
              Conheça a equipe
            </Button>
          </StyledTextWrapper>
        </Flex>
      </Flex>
    </Container>
  </Section>
)

export default homeTeam
