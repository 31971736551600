import styled from "styled-components"

export const StyledTextWrapper = styled.div`
  @media (max-width: 960px) {
    padding: 2rem 1.2rem 1.2rem;
    text-align: center;
  }
  @media (min-width: 960px) {
    max-width: 430px;
    padding: 4rem 0 7rem 0;
  }
`

export default styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
  margin-bottom: 40px;
`
