export default [
  {
    title: "Cirurgias",
    description:
      "Configura as cirurgias realizadas pela nossa equipe de médicos cirurgiões.",
    buttonLabel: "Ver especialidades",
    buttonTo: "/especialidades",
  },
  {
    title: "Consultas",
    description: "Diagnosticamos e realizamos tratamento clínico e cirúrgico.",
    buttonLabel: "Marcar consulta",
    buttonTo: "/marcarconsulta",
    background: "darken",
  },
  {
    title: "Exames",
    description:
      "Saiba as clínicas e exames que você pode realizar com a nossa equipe.",
    buttonLabel: "Marcar exame",
    buttonTo: "/exames",
  },
]
