import styled from "styled-components"
import Flex from "../flex"

export default styled(Flex)`
  padding: 4rem;
  text-align: center;
  ${({ background }) =>
    background
      ? `
          background: rgba(0, 0, 0, 0.2);
        `
      : ``}
`
