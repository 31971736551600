import React from "react"
import PropTypes from "prop-types"
import Flex from "../flex"
import Typography from "../typography"
import Card from "../card"
import { StyledBlock } from "./styled"

const Block = ({ icon: Icon, title, items }) => (
  <StyledBlock>
    <Flex flexWrap="wrap">
      <Flex child flex="none">
        <Card borderRadius="10px" padding="1rem" margin="0 2rem 0 0">
          <Icon />
        </Card>
      </Flex>
      <Flex child flex="auto">
        <Typography variant="h4" component="h3" color="secondary">
          {title}
        </Typography>
        <Flex column>
          {items.map(item => (
            <Flex child>
              <Typography
                key={item.name}
                variant="bodyLarge"
                component="a"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.name}
              </Typography>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  </StyledBlock>
)

Block.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Block
