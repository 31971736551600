import styled from "styled-components"

export const StyledTextWrapper = styled.div`
  padding: 5rem;
  @media (max-width: 960px) {
    padding: 2rem 0 0;
  }
`

export default styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50px 0 50px 0;
`
