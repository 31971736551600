import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Flex from "../flex"
import Card from "../card"

const StyledCard = styled(Card)`
  text-align: center;
  img {
    object-fit: contain;
    width: 150px;
    height: 95px;
  }
  p {
    padding-top: 33px;
    height: 95px;
    margin: 0 !important;
  }
`

const Item = ({ children }) => (
  <Flex child flexGtSm={33} flex={100}>
    <StyledCard borderRadius="8px">{children}</StyledCard>
  </Flex>
)

Item.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Item
