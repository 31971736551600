import React from "react"
import Container from "../container"
import Section from "../section"
import Flex from "../flex"
import Typography from "../typography"
import HomeJumbroTronImage from "../../assets/images/places.png"
import HospitalIcon from "../../assets/svgs/hospital.svg"
import ClinicIcon from "../../assets/svgs/clinic.svg"
import StyledImage from "./styled"
import Block from "./block"
import hospitals from "../../api/hospitals"
import clinics from "../../api/clinics"

const homeWorkPlaces = () => (
  <Section background="tertiary-light">
    <Container>
      <Flex flexWrap="wrap" align="center">
        <Flex child flexGtSm={40} flex={100}>
          <Typography variant="h5" component="h2" color="primary">
            Hospitais e Clínicas
          </Typography>
          <Typography variant="h1" color="secondary">
            Onde atendemos
          </Typography>
          <Typography variant="bodyLarge">
            Confira os locais em que os cirurgiões da GastroCirúrgica realizam
            consultas e cirurgiais.
          </Typography>
          <Block icon={HospitalIcon} title="Hospitais" items={hospitals} />
          <Block icon={ClinicIcon} title="Clínicas" items={clinics} />
        </Flex>
        <Flex child flexGtSm={60} flex={100}>
          <StyledImage
            src={HomeJumbroTronImage}
            alt="Hospitais em que GastroCírurgica atende"
          />
        </Flex>
      </Flex>
    </Container>
  </Section>
)

export default homeWorkPlaces
