import React from "react"
import PropTypes from "prop-types"
import Typography from "../typography"
import Button from "../button"
import Flex from "../flex"
import ArrowIcon from "../../assets/svgs/arrow.svg"

const HomeArticlesItem = ({ title, subtitle, year, url }) => (
  <article style={{ marginTop: "1.5rem" }}>
    <Flex>
      <Flex child flex="none" style={{ padding: "0.5rem 0.75rem 0" }}>
        <ArrowIcon />
      </Flex>
      <Flex child flex="auto">
        {subtitle && (
          <div style={{ padding: 6 }}>
            <Typography variant="bodyLarge" removeMargin>
              {subtitle}
            </Typography>
          </div>
        )}
        <Button htmlType="a" href={url} target="_blank" color="header">
          {title}
        </Button>
        <div style={{ padding: 6 }}>
          <Typography variant="body">{year}</Typography>
        </div>
      </Flex>
    </Flex>
  </article>
)

HomeArticlesItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default HomeArticlesItem
