import React from "react"
import Container from "../container"
import Section from "../section"
import Typography from "../typography"
import Flex from "../flex"
import UnimedImage from "../../assets/images/unimed.png"
import SCSaudeImage from "../../assets/images/scsaude.png"
import Item from "./item"

const homeCovenants = () => (
  <Section background="secondary" padding="2.5rem 0">
    <Container>
      <Flex flexWrap="wrap" align="center">
        <Flex child flexGtSm={40} flex={100}>
          <Typography variant="h1" color="light">
            Convênios aceitos
          </Typography>
          <Typography variant="h5" component="h2" color="primary">
            Demais convênios - com reembolso para cirurgias
          </Typography>
        </Flex>
        <Flex child flexGtSm={60} flex={100}>
          <Flex flexWrap="wrap" align="flex-start">
            <Item>
              <img src={UnimedImage} alt="Unimed" title="Unimed" />
            </Item>
            <Item>
              <img src={SCSaudeImage} alt="SC Saúde" title="SC Saúde" />
            </Item>
            <Item>
              <Typography variant="h3" component="p" color="primary">
                Particular
              </Typography>
            </Item>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  </Section>
)

export default homeCovenants
