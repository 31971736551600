import React from "react"
import PropTypes from "prop-types"
import Button from "../button"
import Typography from "../typography"
import StyledFlex from "./styled"

const HomeCtasItem = ({
  title,
  description,
  buttonLabel,
  buttonTo,
  background,
}) => (
  <StyledFlex
    child
    flexGtSm={33}
    flex={100}
    spacing={4}
    background={background}
  >
    <Typography color="light" variant="h3" component="h2">
      {title}
    </Typography>
    <Typography color="tertiary" variant="bodyLarge">
      {description}
    </Typography>
    <Button to={buttonTo} htmlType="link" color="link">
      {buttonLabel}
    </Button>
  </StyledFlex>
)

HomeCtasItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonTo: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  background: PropTypes.string,
}

HomeCtasItem.defaultProps = {
  background: null,
}

export default HomeCtasItem
