import React from "react"
import Container from "../container"
import Section from "../section"
import Flex from "../flex"
import Typography from "../typography"
import certificates from "../../api/certificates"
import articles from "../../api/articles"
import researches from "../../api/researches"
import Item from "./item"

const HomeArticles = () => (
  <Container>
    <Section padding="2.5rem 0">
      <Typography variant="h5" component="h2" color="primary">
        Certificados e Apresentações em Congressos
      </Typography>
      <Typography variant="h1" color="secondary">
        Congressos
      </Typography>
      <Typography variant="bodyLarge">
        Veja os certificados de trabalhos apresentados em congressos.
      </Typography>
      <Flex flexWrap="wrap" justify="space-between">
        {certificates.map(certificate => (
          <Flex child flexGtSm={45} flex={100}>
            <Item key={certificate.url} {...certificate} />
          </Flex>
        ))}
      </Flex>
    </Section>
    <Section padding="2.5rem 0">
      <Typography variant="h5" component="h2" color="primary">
        Artigos
      </Typography>
      <Typography variant="h1" color="secondary">
        Artigos Publicados
      </Typography>
      <Typography variant="bodyLarge">
        Leia os artigos publicados pelos cirurgiões da GastroCirúrgica.
      </Typography>
      <Flex flexWrap="wrap" justify="space-between">
        {articles.map(article => (
          <Flex child flexGtSm={45} flex={100}>
            <Item key={article.url} {...article} />
          </Flex>
        ))}
      </Flex>
    </Section>
    <Section padding="2.5rem 0">
      <Typography variant="h5" component="h2" color="primary">
        Atividade de Pesquisa Acadêmica com Resultados Cirúrgicos da Equipe
      </Typography>
      <Typography variant="h1" color="secondary">
        Pesquisa Acadêmica
      </Typography>
      <Flex flexWrap="wrap" justify="space-between">
        {researches.map(research => (
          <Flex child flexGtSm={45} flex={100}>
            <Item key={research.url} {...research} />
          </Flex>
        ))}
      </Flex>
    </Section>
  </Container>
)

export default HomeArticles
