import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HomeAboveTheFold from "../../components/homeAboveTheFold"
import HomeTeam from "../../components/homeTeam"
import HomeWorkPlaces from "../../components/homeWorkPlaces"
import HomeCtas from "../../components/homeCtas"
import HomeCovenants from "../../components/homeCovenants"
import HomeArticles from "../../components/homeArticles"

const Index = props => (
  <Layout {...props}>
    <SEO
      title="GastroCirúrgica - Cirurgia do Aparelho Digestivo - Floripa"
      description="Cirurgiões que oferecem diversas especialidades médicas para cuidar do seu aparelho digestivo em florianópolis - SC."
    />
    <HomeAboveTheFold />
    <HomeCtas />
    <HomeTeam />
    <HomeWorkPlaces />
    <HomeCovenants />
    <HomeArticles />
  </Layout>
)

export default Index
