export default [
  {
    subtitle: "Micro cirurgia transanal endoscópica",
    title:
      "RESSECÇÃO DE NEOPLASIA DO RETO MÉDIO OCUPANDO 3/4 DA LUZ INTESTINAL POR TAMIS (CIRURGIA TRANSANAL MINIMAMENTE INVASIVA)",
    year: "2017",
    url: "/cirurgia-transanal-minimamente-invasiva",
  },
  {
    title:
      "GASTRECTOMIA PROXIMAL VIDEOLAPAROSCÓPICA PARA TRATAMENTO DE LEIOMIOMA GÁSTRICO - CASO CLÍNICO",
    year: "2016",
    url:
      "/certificados/GASTRECTOMIA_PROXIMAL_VIDEOLAPAROSCOPICA_PARA_TRATAMENTO_DE_LEIOMIOMA_GASTRICO.pdf",
  },
  {
    title:
      "GASTRECTOMIA TOTAL AMPLIADA COM PANCREATECTOMIA CORPOCAUDAL, ESPLENECTOMIA E LINFADENECTOMIA A D2 VIDEOLAPAROSCÓPICA POR ADENOCARCINOMA GÁSTRICO",
    year: "2016",
    url:
      "/certificados/GASTRECTOMIA_TOTAL_AMPLIADA_COM_PANCREATECTOMIA_CORPOCAUDAL_ESPLENECTOMIA_E_LINFADENECTOMIA_A_D2_VIDEOLAPAROSCOPICA_POR_ADENOCARCINOMA_GASTRICO.pdf",
  },
  {
    title:
      "TOTALIZAÇÃO VIDEOLAPAROSCÓPICA DE GASTRECTOMIA POR ADENOCARCINOMA DE COTO GÁSTRICO – CASO CLÍNICO",
    year: "2016",
    url:
      "/certificados/TOTALIZACAO_VIDEOLAPAROSCOPICA_DE_GASTRECTOMIA_POR_ADENOCARCINOMA_DE_COTO_GASTRICO.pdf",
  },
  {
    title:
      "CARCINOMA NEUROENDÓCRINO GÁSTRICO - TRATAMENTO LAPAROSCÓPICO - CASO CLÍNICO",
    year: "2014",
    url: "/certificados/CARCINOMA_NEUROENDOCRINO_GASTRICO.pdf",
  },
  {
    title:
      "ENDOSCOPIA DIGESTIVA ALTA COMO ROTINA NO PRÉ-OPERATÓRIO DA CIRURGIA BARIÁTRICA",
    year: "2012",
    url:
      "/certificados/ENDOSCOPIA_DIGESTIVA_ALTA_COMO_ROTINA_NO_PRE_OPERATORIO_DA_CIRURGIA_BARIATRICA.jpeg",
  },
  {
    title:
      "ENDOSCOPIA DIGESTIVA ALTA COMO ROTINA NO PRÉ-OPERATÓRIO DA CIRURGIA BARIÁTRICA",
    year: "2012",
    url:
      "/certificados/ENDOSCOPIA_DIGESTIVA_ALTA_COMO_ROTINA_NO_PRE_OPERATORIO_DA_CIRURGIA_RABIATRICA.jpeg",
  },
  {
    title: "PERDA DE PESO NO PÓS-OPERATÓRIO DO BYPASS GÁSTRICO EM X-DE-ROUX",
    year: "2012",
    url:
      "/certificados/PERDA_DE_PESO_NO_POS_OPERATORIO_DO_BYPASS_GASTRICO_EM_X_DE_ROUX.jpeg",
  },
  {
    title:
      "RESSECÇÃO LAPAROSCÓPICA DO CÂNCER DA JUNÇÃO RETOSSIGMOÍDEA EM ESTADIO T4",
    year: "2011",
    url:
      "/certificados/RESSECCAO_LAPAROSCOPICA_DO_CANCER_DA_JUNCAO_REOSSIGMOIDEA_EM_ESTADIO_T4.jpeg",
  },
  {
    title:
      "RETOCOLECTOMIA ESQUERDA VIDEOLAPAROSCÓPICA POR DOLICOMEGACÓLON ESQUERDO IDIOPÁTICO",
    year: "2011",
    url:
      "/certificados/RETOCOLECTOMIA_ESQUERDA_VIDEOLAPAROSCOPICA_POR_DOLICOMEGACOLON_ESQUERDO_IDIOPATICO.jpeg",
  },
  {
    title:
      "MANEJO LAPAROSCÓPICO DA FÍSTULA COLORRETAL SEM ILEOSTOMIA PROTETORA",
    year: "2011",
    url:
      "/certificados/MANEJO_LAPAROSCOPICO_DA_FISTULA_COLORRETAL_SEM_ILEOSTOMIA_PROTETORA.jpeg",
  },
  {
    title:
      "MANEJO LAPAROSCÓPICO DA OBSTRUÇÃO INTESTINAL EM PÓS-OPERATÓRIO DE COLECTOMIA ESQUERDA",
    year: "2011",
    url:
      "/certificados/MANEJO_LAPAROSCOPICO_DA_OBSTRUCAO_INTESTINAL_EM_POS_OPERATORIO_DE_COLECTOMIA_ESQUERDA.jpeg",
  },
]
