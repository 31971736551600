import React from "react"
import Button from "../button"
import Container from "../container"
import Section from "../section"
import Flex from "../flex"
import Typography from "../typography"
import HomeJumbroTronImage from "../../assets/images/equipe_gastrocirurgica_jaleco.png"
import { OVERLINE, HEADLINE, DESCRIPTION, BUTTON_LABEL, BUTTON_SECONDARY_LABEL } from "./constants"
import StyledImage, { StyledTextWrapper } from "./styled"

const HomeAboveTheFold = () => (
  <Section background="grey" padding="0">
    <Container xsPadding="0">
      <Flex flexWrap="wrap" align="flex-end">
        <Flex child flexGtMd={40} flexMd={45} flexSm={100} flexXs={100}>
          <StyledTextWrapper>
            <Typography variant="h4" component="h2">
              {OVERLINE}
            </Typography>
            <Typography variant="h1Big" color="secondary">
              {HEADLINE}
            </Typography>
            <Typography variant="bodyLarge">{DESCRIPTION}</Typography>
            <Flex gap={1} flexWrap="wrap" align="center">
              <Flex child flex="none">
                <Button
                  color="primary"
                  size="large"
                  shape="round"
                  htmlType="link"
                  to="/marcarconsulta"
                >
                  {BUTTON_LABEL}
                </Button>
              </Flex>
              <Flex child flex="none">
                <Button
                  color="link"
                  size="large"
                  shape="round"
                  htmlType="link"
                  to="/exames"
                >
                  {BUTTON_SECONDARY_LABEL}
                </Button>
              </Flex>
            </Flex>
          </StyledTextWrapper>
        </Flex>
        <Flex child flexGtMd={60} flexMd={55} flexSm={100} flexXs={100}>
          <StyledImage
            src={HomeJumbroTronImage}
            alt="Equipe GastroCirúrgica de Jaleco"
          />
        </Flex>
      </Flex>
    </Container>
  </Section>
)

export default HomeAboveTheFold
