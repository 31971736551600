import React from "react"
import Container from "../container"
import Section from "../section"
import Flex from "../flex"
import HomeCtasItem from "./item"
import ITEMS from "./contants"

const HomeCtas = () => (
  <Container>
    <Section
      background="secondary"
      borderRadius="10px"
      padding="0"
      style={{ position: "relative", zIndex: 1, margin: "-50px 0 0" }}
    >
      <Flex flexWrap="wrap">
        {ITEMS.map(item => (
          <HomeCtasItem key={item.title} {...item} />
        ))}
      </Flex>
    </Section>
  </Container>
)

export default HomeCtas
