export default [
  {
    title: "Duodenopancreatectomias realizadas em cidade de médio porte",
    year: "2020",
    url: "https://riuni.unisul.br/handle/12345/9705",
  },
  {
    title:
      "Prevalência e fatores associados à anemia em pacientes submetidos ao bypass gástrico em y de roux",
    year: "2018",
    url: "https://riuni.unisul.br/handle/12345/6422",
  },
  {
    title:
      "Impacto tardio do tratamento laparoscópico da Endometriose Profunda Infiltrativa com ressecção segmentar colorretal.",
    year: "2017",
    url: "https://riuni.unisul.br/handle/12345/4369",
  },
  {
    title: "Perda de peso no pós-operatório do bypass gástrico em Y-de-Roux",
    year: "2018",
    url:
      "/pesquisa/PERDA_DE_PESO_NO_POS_OPERATORIO_DO_BYPASS_GASTRICO_EM_Y_DE_ROUX.pdf",
  },
  {
    title:
      "Endoscopia digestiva alta como rotina no pré-operatório da cirurgia bariátrica",
    year: "2018",
    url:
      "/pesquisa/ENDOSCOPIA_DIGESTIVA_ALTA_COMO_ROTINA_NO_PRE_OPERATORIO_DA_CIRURGIA_BARIATRICA.pdf",
  },
]
