export default [
  {
    title:
      "IMPACTO TARDIO DO TRATAMENTO LAPAROSCÓPICO DA ENDOMETRIOSE PROFUNDA INFILTRATIVA COM RESSECÇÃO SEGMENTAR COLORRETAL",
    year: "2018",
    url:
      "/artigos/IMPACTO_TARDIO_DO_TRATAMENTO_LAPAROSCOPICO_DA_ENDOMETRIOSE_PROFUNDA_INFILTRATIVA_COM_RESSECCAO_SEGMENTAR_COLORRETAL.pdf",
  },
  {
    title:
      "RESSECÇÕES COLORRETAIS LAPAROSCÓPICAS E LAPAROTÔMICAS NO CÂNCER COLORRETAL",
    year: "2012",
    url:
      "/artigos/RESSECCOES_COLORRETAIS_LAPAROSCOPICAS_E_LAPAROTOMICAS_NO_CANCER_COLORRETAL.pdf",
  },
]
